import { Link } from 'react-router-dom'
import logo from '../images/logo.png';
import { useState } from "react";


export function Navbar() {

    // const history = useHistory();

    const [isActive, setisActive] = useState(false);

    const toggleNavbar = () => {
        setisActive(!isActive);
    }

    const navigateToItems = () => {
        //   history.push('/items');
    };

    return (
        <div className='navigation-bar'>
            <section className='hero is-small'>
                <nav>
                    <section className='hero-body'>
                        <div className='container has-text-centered'>
                            <div className='columns'>
                                <div className='column is-one-fifth'>
                                    <img className='is-16x16' src={logo} alt='logo' />
                                </div>
                                <div className='column'>
                                    <h1 className='title'>The Earth Kitchen</h1>
                                    <p className='subtitle'>Indulge in the authentic flavors of South India, served with tradition and taste</p>
                                </div>
                            </div>
                        </div>
                        <div id="navbarMenu" className='navbar-menu'>
                            <div className='navbar-end'>
                                <Link className="navbar-item" to="/">
                                    <button>Home</button>
                                </Link>
                                <Link className="navbar-item" to="/items">
                                    <button>View Items</button>
                                </Link>
                                <Link className="navbar-item" to="/cart">
                                    <button>Go to Cart</button>
                                </Link>
                            </div>
                        </div>
                        <a role='button' className="navbar-burger" data-target="navbarMenu" aria-expanded="false" onClick={toggleNavbar}>
                            <span className='burger burger1' aria-hidden='true'></span>
                            <span className='burger burger2' aria-hidden='true'></span>
                            <span className='burger burger3' aria-hidden='true'></span>
                        </a>
                    </section>
                </nav >
            </section>
        </div>


    )
}


{/* <div class="media">
<div class="media-left">
    <figure class="image is-48x48">
        <img src={logo} alt="Image" />
    </figure>
</div>
<div class="media-content">
    <p class="title is-4 no-padding">The Earth Kitchen</p>
    <p>
        <span class="title is-6">
            <a href="">Bringing food to your doorstep</a> </span> </p>
    <p class="subtitle is-6"></p>
</div>
</div> */}