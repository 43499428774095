import image from '../images/homepage.png';


export function Home() {
    return (
        <div>
            <div className="main-content" style={{ background: '#FFFFFF', color: 'black' }}>
                <div className="section-light aboutn-me" id="about-me">
                    <div className="container">
                        <div className="column is-12 about-me">
                            <h1 className="title has-text-centered section-title">ABOUT US</h1>
                            <span>  </span>
                            <span>  </span>
                            <span>  </span>
                        </div>
                        <div className="columns is-multiline">
                            <div className="column is-6 has-vertically-aligned-content box" data-aos="fade-right" style={{ background: '#fff7e6' }}>

                                <p className="is-larger has-text-centered">
                                    <strong style={{ color: '#4e2009' }}>Authentic Indian Flavors, Freshly Delivered, Sustainably Packed</strong><br></br>
                                </p>
                                <br /><br></br>
                                <p>
                                    At The Earth Kitchen, we specialize in crafting authentic South Indian flavors with a modern, plant-based twist. From aromatic curries and spiced biryanis to crispy dosas, our menu features a delightful mix of traditional South Indian dishes and creative vegan fusion options. Each meal is made with fresh, locally sourced ingredients, offering a wholesome and nourishing experience that reflects the vibrant tastes of India.</p><br></br>
                                <p>In addition to our home delivery service, we also cater to small and medium-sized events, bringing the same authentic flavors and attention to detail to your special occasions. Whether it's a private gathering, corporate event, or celebration, we work closely with you to create a customized menu that will delight your guests and elevate the event experience. And to ensure we're doing our part for the environment, all our meals are packaged using eco-friendly, minimal plastic packaging. With every dish, we celebrate the rich heritage of South Indian cuisine and the joy of sharing great food in a sustainable way.</p>
                                <br />
                                <br />
                                <div className="is-divider"></div>
                                <div className="columns about-links">
                                    <div className="column">
                                        <p className="heading">
                                            <strong style={{ color: '#4e2009' }}>Give me a ring</strong>
                                        </p>
                                        <p className='subheading'>+61-0481 844 704</p>
                                    </div>
                                    <div className="column">
                                        <p className="heading">
                                            <strong style={{ color: '#4e2009' }}>Email Me</strong>
                                        </p>
                                        <p className='subheading'>contacttheearthkitchen@gmail.com</p>
                                    </div>
                                    <div className="column">
                                        <p className="heading">
                                            <strong style={{ color: '#4e2009' }}>Follow Us</strong>
                                        </p>
                                        <a className='subheading' href='https://www.instagram.com/theearthkitchenau/' target='_blank'>Instagram</a><br></br>
                                        <a className='subheading' href='https://www.facebook.com/people/The-Earth-Kitchen/61564147460752/' target='_blank'>Facebook</a>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6 right-image" data-aos="fade-left">
                                <img className="is-rounded" src={image} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ background: '#1f2e2e' }} className="footer">
                <h3>License & Registration:</h3>
                <p>This company is registered under Food Act 1984. ABN: 27 332 917 873</p>
            </div>
        </div >

    )
}


{/* <section className="hero is-warning">
<div className="hero-body">
    <p className='title'>
        THE EARTH KITCHEN
    </p>
    <p className='subtitle'>
        Order your favorite food
    </p>
    <p>ABN 93 2323 3 323 23</p>
</div>
</section>
<section className="hero is-medium">
<div className="hero-head">
    <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                    <a className="navbar-item is-active">
                        Home
                    </a>
                    <a className="navbar-item">
                        Blog Posts
                    </a>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <a href="#">
                            <i className="fab fa-facebook"></i>
                        </a>
                        <a href="#">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                            <i className="fas fa-rss-square"></i>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<div className="hero-body">
    <div className="container has-text-centered">
        <img src="https://cdn.emk.dev/templates/bulma-logo-light.png" width="150" height="40" />
        <h2 className="subtitle">
            Ghost's Casper theme made with Bulma
        </h2>
    </div>
</div>
</section>
<div>
<div>
    <title>The Earth Kitchen</title>
</div>
</div> */}