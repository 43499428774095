import mysoreMasalaDosa from '../images/masala-dose.jpg';

export const MenuItems = [
    {
        id: 10,
        name: '⭐️⭐️⭐️ COMBO 1 ⭐️⭐️⭐️',
        servings: '6 Idlis, 2 Vadas of your choice, Khara Bhath, Kesari Bhath & Mysore Masala Dosa',
        description: 'Enjoy a complete South Indian feast with crispy Mysore Masala Dosa, Idli, Vada & Chow Chow Bath served with sambar and chutneys.',
        price: 28,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 11,
        name: '⭐️⭐️ COMBO 2 ⭐️⭐️',
        servings: '4 Idlis, 1 Vada of your choice, Khara Bhath, and Kesari Bhath',
        description: 'Delight in a South Indian combo with Idlis, Vada, Chow Chow Bath served with sambar and chutneys.',
        price: 18,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {

        id: 1,
        name: 'Idly',
        servings: '6 pieces',
        description: 'Soft and fluffy steamed rice cakes served with coconut chutney and sambar.',
        price: 10,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 2,
        name: 'Idly and Vada',
        servings: 'Idly (4pcs) and Vada (2pcs)',
        description: 'A classic South Indian combo: soft, steamed idly and crispy, golden vada, served with chutney and sambar.',
        price: 12,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 3,
        name: 'Medu Vada',
        servings: '4 pieces',
        description: 'Crispy, golden-fried medu vada made from urad dal, served with chutney and sambar.',
        price: 10,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 4,
        name: 'Masala Vada',
        servings: '4 pieces',
        description: 'Crispy, spiced lentil fritters made with chana dal, packed with flavors and served with chutney.',
        price: 10,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 5,
        name: 'Pineapple Kesari Bath',
        servings: '6 serves',
        description: 'A flavorful semolina dish with pineapple, infused with ghee, and garnished with cashews and raisins.',
        price: 12,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 6,
        name: 'Khara Bath',
        servings: '2 serves',
        description: 'A savory semolina dish with mixed vegetables, spices, and a hint of ghee, served hot and flavorful.',
        price: 12,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 7,
        name: 'Chow Chow Bath',
        servings: '1 serve each',
        description: 'A perfect combo of savory Khara Bhath and Pineapple Kesari Bhath, served together for a delightful contrast of flavors.',
        price: 12,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 8,
        name: 'Mysuru Masala Dosa',
        servings: '1 piece',
        description: 'Crispy dosa filled with spicy potato stuffing, topped with tangy Mysore chutney, and served with sambar and chutneys.',
        price: 14,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    },
    {
        id: 9,
        name: 'Set Dosa',
        servings: '3 pieces',
        description: 'A savory semolina dish with mixed vegetables, spices, and a hint of ghee, served hot and flavorful.',
        price: 13,
        quantity: 1,
        imageUrl: mysoreMasalaDosa
    }
]