import * as React from 'react'
import '../css/ShowItem.css';

export function ShowItem({ name, description, price, image, addItemsToCart, servings }) {

    const handleAddToCart = () => {
        addItemsToCart({ name, price, image, quantity: 1 });
    }

    return (
        <div className='column is-4'>
            <div className='card'>
                <div className='card-image'>
                    <figure className='image is-4by3'>
                        <img src={image} alt={name} />
                    </figure>
                </div>
                <div className='card-content'>
                    <div className='media'>
                        <div className='media-content'>
                            <p className='title is-4 no-padding'>{name}</p>
                        </div>
                    </div>
                    <div className="content">
                        {description}
                        <div className="background-icon"><span className="icon-twitter"></span>Servings: {servings}</div>
                        <p className="subtitle is-6">Price: ${price}</p>
                        <button onClick={handleAddToCart} className="button is-link modal-button">Add to Cart</button>
                    </div>
                </div>
            </div>
        </div>
    )

    // return (
    //     <div className="cell">
    //         <div className="card list-item cell">
    //             <div className="card-image">
    //                 <figure className="image is-4by3">
    //                     <img key={key} className="image is-4by3" src={image} alt={name} />
    //                 </figure>
    //             </div>
    //             <div className="card-content">
    //                 <div className="media-content">

    //                     <div className="title is-4">{name}</div>
    //                     <div className="content">{description}</div>
    //                     <div>Price: ${price}</div>

    //                 </div>

    //                 <button onClick={handleAddToCart} className="button is-primary button-container">Add to Cart</button>
    //             </div>
    //         </div>
    //     </div>
    // )
}