import 'bulma/css/bulma.css'
import './css/ShowItem.css'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'

import { Home } from './components/Home'
import { ItemList } from "./components/ItemList";
import { Cart } from "./components/Cart";
import { CheckoutPage } from "./components/CheckoutPage";
import { MenuItems } from "./constants/MenuItems";
import { useState } from "react";
import { Layout } from './Layout';

function App() {

    const [cartItems, setCartItems] = useState([]);

    const addItemsToCart = (item) => {
        const existingCartItem = cartItems.find(cartItem => cartItem.name === item.name)
        if (existingCartItem) {
            const updatedCartItems = cartItems.map(cartItem => cartItem.name === item.name ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem);
            setCartItems([...updatedCartItems]);
        }
        else
            setCartItems([...cartItems, item]);
    }

    const deleteItemFromCart = (itemName) => setCartItems(cartItems.filter(cartItem => cartItem.name !== itemName));

    return (
        <Router>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/items" element={<ItemList items={MenuItems} addItemsToCart={addItemsToCart} />} />
                    <Route path="/cart" element={<Cart items={cartItems} onDelete={deleteItemFromCart} />} />
                    <Route path="/checkout" element={<CheckoutPage addItemsToCart={addItemsToCart} cartItems={cartItems} />} />
                </Route>
            </Routes>
        </Router>
    )

    // return (
    //     <div>
    //         <section className="hero is-warning">
    //             <div className="hero-body">
    //                 <p className='title'>
    //                     THE EARTH KITCHEN
    //                 </p>
    //                 <p className='subtitle'>
    //                     Order your favorite food
    //                 </p>
    //                 <p>ABN 93 2323 3 323 23</p>
    //             </div>
    //         </section>
    //         <section className="hero is-medium">
    //             <div className="hero-head">
    //                 <div className="container">
    //                     <nav className="navbar" role="navigation" aria-label="main navigation">

    //                         <div id="navbarBasicExample" className="navbar-menu">
    //                             <div className="navbar-start">
    //                                 <a className="navbar-item is-active">
    //                                     Home
    //                                 </a>
    //                                 <a className="navbar-item">
    //                                     Blog Posts
    //                                 </a>
    //                             </div>

    //                             <div className="navbar-end">
    //                                 <div className="navbar-item">
    //                                     <a href="#">
    //                                         <i className="fab fa-facebook"></i>
    //                                     </a>
    //                                     <a href="#">
    //                                         <i className="fab fa-twitter"></i>
    //                                     </a>
    //                                     <a href="#">
    //                                         <i className="fas fa-rss-square"></i>
    //                                     </a>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </nav>
    //                 </div>
    //             </div>
    //             <div className="hero-body">
    //                 <div className="container has-text-centered">
    //                     <img src="https://cdn.emk.dev/templates/bulma-logo-light.png" width="150" height="40" />
    //                     <h2 className="subtitle">
    //                         Ghost's Casper theme made with Bulma
    //                     </h2>
    //                 </div>
    //             </div>
    //         </section>
    //         <div className='grid'>
    //             <ItemList items={MenuItems} addItemsToCart={addItemsToCart} />
    //         </div>
    //         <div>
    //             <Cart items={cartItems} onDelete={deleteItemFromCart} />
    //         </div>
    //         <div>
    //             <CheckoutPage addItemsToCart={addItemsToCart} cartItems={cartItems} />
    //         </div>
    //     </div>
    // );
}

export default App;