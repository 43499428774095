import { useState } from "react";

export function ListItemInCart({ item, index, onDelete }) {
    //  const [showEdit, setShowEdit] = useState(false);
    //   const toggleEdit = () => { setShowEdit(!showEdit) };

    const handleDelete = () => {
        onDelete(item.name);
    }


    return (
        <div className="cart-item">
            <div className="columns">
                <div className="column is-4">
                    <figure className="image is-64x64">
                        <img src={item.image} alt={item.name} />
                    </figure>
                </div>
                <div className="column is-4">
                    <p className="subtitle is-4">{item.name}</p>
                </div>
                <div className="column is-3">
                    <p className="subtitle is-4">{item.quantity}</p>
                </div>
                <div className="column is-3">
                    <p className="subtitle is-4">{item.price}</p>
                </div>
                <div className="column is-3">
                    <button onClick={handleDelete} className="button is-small is-danger">Remove</button>
                </div>
            </div>
        </div>
    )
}