import React from 'react';
import '../css/Cart.css'; // Import the CSS file for styling
import { ListItemInCart } from './ListItemInCart';

export function Cart({ items, onDelete }) {
    const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);

    const listCartItems = () => {
        const listItems = items.map((item, index) => (
            <ListItemInCart item={item} onDelete={onDelete} />
        ))
        console.log(listItems)
        return <div>
            {listItems}
        </div>
    }

    return (
        <div className='container'>
            <div className='columns'>
                <div className="column">
                    <h2 className='title'>Cart</h2>
                    <div className="box">
                        <div className="cart-item">
                            <div className="columns is-full">
                                <div className="column is-one-fifth">
                                </div>
                                <div className="column is-one-forth">
                                    <p className="has-text-white">Item</p>
                                </div>
                                <div className="column is-one-forth">
                                    <p className="has-text-white">Quantity</p>
                                </div>
                                <div className="column is-one-forth">
                                    <p className="has-text-white">Price</p>
                                </div>
                            </div>
                        </div>
                        <div className='row columns'>{listCartItems()}</div>
                        <h2 className='title'>Total: ${total.toFixed(2)}</h2>
                        <button disabled='disabled' className="button is-primary is-fullwidth">Proceed to Checkout</button>
                        <h2><strong style={{ color: 'black' }}>Please Note:</strong></h2>
                        <h2>We are not yet ready to take online orders. To place your order, please call or WhatsApp us at <strong style={{ color: 'black' }}>+61-0481 844 704</strong>. We're happy to assist you and ensure you get your delicious meal delivered fresh!</h2>
                    </div>
                </div>
            </div>
        </div>

    );
};
