
import '../css/Checkout.css';

export function CheckoutPage() {

    const onSubmit = (e) => {

    }

    return (
        <div className='box'>
            <div className='is-half' style={{ background: 'white', margin: 10 }}>
                <h1 className='title'>Checkout</h1>
                <form>
                    <div className="field">
                        <label className="label">Name</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="e.g Alex Smith" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">WhatsApp Contact Number</label>
                        <div className="control">
                            <input className="input" type="tel" placeholder="e.g. +61-0432229918" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Address Line 1</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="e.g. 21/699 Collins St" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Suburb</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="e.g. Cobblebank" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Postcode</label>
                        <div className="control">
                            <input className="input" type="number" placeholder="e.g. 3338" />
                        </div>
                    </div>
                    <button className='button is-primary is-fullwidth'>Place Order</button>
                </form>
            </div>
        </div>
    );
}