import { ShowItem } from './ShowItem';
import '../css/cards.css'


export function ItemList({ items, addItemsToCart }) {
    const renderedItems = items.map((item, index) => {
        return (
            <ShowItem key={index} name={item.name} description={item.description} price={item.price} image={item.imageUrl} servings={item.servings} addItemsToCart={addItemsToCart} />
        )

    })
    return (
        <div className="container box" style={{ background: '#fff3e6' }}>
            <div className='section'>
                <div className='columns'>
                    <div className='column has-text-centered'>
                        <h1 className='title' style={{ color: 'ghostwhite' }}>Weekend Menu</h1>
                    </div>
                </div>
                <div id='app' className='row columns is-multiline'>
                    {renderedItems}
                </div>
            </div>
        </div>
    )
}


//name, description, price, image 